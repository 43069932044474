.movie-type {
    margin-left: 0.6em;
}

.no-border {
    border: 0;
    box-shadow: none; /* You may want to include this as bootstrap applies these styles too */
}

input {
    border: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

input:focus {
    outline: none !important;
}

*:focus {
    outline: none;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 100%;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
    margin-left: 10px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 100%;
    border: none;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 1px solid #aaa;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.suggestion-title {
    margin-left: 5px;
}

#search-bar {
    padding-top: 70px;
    /* position: -webkit-sticky;
    position: sticky;
    top: 0; */
}
