.image-container {
    line-height: 0px;
    position: relative;
}

.image-text {
    text-align: left;
    padding-left: 5px;
    padding-bottom: 15px;
    bottom: 0;
    position: absolute;
    left: 0;
    color: aliceblue;
    height: 30px;
    line-height: 15px;
}

.image-text-left {
    text-align: left;
    padding-left: 5px;
    padding-bottom: 15px;
    bottom: 0;
    position: absolute;
    left: 0;
    color: aliceblue;
    height: 30px;
    width: 250px;
    line-height: 15px;
}

.image-text-right {
    text-align: left;
    padding-left: 5px;
    padding-bottom: 15px;
    bottom: 0;
    position: absolute;
    left: 250px;
    color: aliceblue;
    height: 30px;
    width: 250px;
    line-height: 15px;
}

.puzzle {
    display: inline-block;
}
